import * as React from "react"
import PropTypes from "prop-types"
import "./footer.css"

const Footer = ({ author }) => (
  <footer className="footer-container">
    <span className="copyright">© {new Date().getFullYear()}, Keady Carpentry Ltd</span>
    <span><strong>|</strong></span>
    <span className="site-design">Site design by <a href="https://www.linkedin.com/in/jilliankmartin">{author}</a></span>
  </footer>
)

Footer.propTypes = {
  author: PropTypes.string,
}

Footer.defaultProps = {
  author: ``,
}

export default Footer