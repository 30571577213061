import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./header.css"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <header>
    <Link
    to="/"
    style={{
      color: `white`,
      textDecoration: `none`,
    }}
    >
      <StaticImage
      className = "logo-image"
      src="../images/logo.jpg"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Logo"
      />
    </Link>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header