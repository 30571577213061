import * as React from "react"
import PropTypes from "prop-types"
import "./contactUs.css"

const ContactUs = ({ props }) => (
  <aside>
    <span ><b>Ph:</b> 604-727-6902</span>
    <span><b>Email:</b> <a href="mailto:stephenkeady@hotmail.com">stephenkeady@hotmail.com</a></span>
  </aside>
)

ContactUs.propTypes = {
  siteTitle: PropTypes.string,
}

ContactUs.defaultProps = {
  siteTitle: ``,
}

export default ContactUs